<template>

  <v-dialog
    v-model="dialog"
    eager
    scrollable
    persistent max-width="600px"
    @keydown.esc="dialog = isDirty()">

    <template v-slot:activator="{ on: dialog }">

      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            fab
            x-small
            v-on="{ ...dialog, ...tooltip }">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New User</span>
      </v-tooltip>
    </template>

    <v-form v-model="isValid" ref="form" @submit.prevent="save">

      <v-card>

        <v-card-title class="headline">
          New User

          <v-spacer />

          <v-switch
            v-if="$vuetify.breakpoint.smAndUp"
            v-model="active"
            inset
            dark
            :label="active ? 'Active' : 'Suspended'" />
        </v-card-title>

        <v-divider />

        <v-card-text>
            <v-row>

              <v-col cols="12">
                <v-switch
                  v-if="$vuetify.breakpoint.xsOnly"
                  v-model="active"
                  inset
                  :label="active ? 'Active' : 'Suspended'" />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  hint="Required"
                  persistent-hint
                  label="Name"
                  outlined
                  clearable
                  inputmode="text"
                  v-model="user.account_name"
                  :rules="[rules.required, rules.min(2)]"
                  :background-color="isFieldDirty('account_name') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hint="Required"
                  persistent-hint
                  label="Username"
                  outlined
                  clearable
                  v-model="user.username"
                  :rules="[rules.min(4)]"
                  :background-color="isFieldDirty('username') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hint="Required"
                  persistent-hint
                  label="Password"
                  outlined
                  password
                  clearable
                  :counter="(user.password || '').length > 0"
                  v-model="user.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  :rules="[rules.min(8)]"
                  :background-color="isFieldDirty('password') ? '#faf9f5' : ''"
                  />
              </v-col>

              <v-col cols="1" class="px-4 py-0">
                <v-switch
                  label="CardNet"
                  inset
                  v-model="user.isCardNetUser" />
              </v-col>

              <v-col cols="12" class="px-4 pl-6">
                <v-expand-transition>

                  <div v-show="user.isCardNetUser">
                    <div class="d-flex">
                      <v-switch
                        class="flex-shrink-1"
                        inset
                        v-model="user.allChannels"
                        label="All Channels" />
                    </div>

                    <v-expand-transition>
                      <v-autocomplete
                        v-show="!user.allChannels"
                        label="Channels"
                        v-model="user.channels"
                        multiple
                        chips
                        clearable
                        deletable-chips
                        outlined
                        :placeholder="user.allChannels ? 'All Channels' : 'Select Channels'"
                        :items="availableChannels" />
                    </v-expand-transition>

                    <div class="d-flex">
                      <v-switch
                        inset
                        class="flex-shrink-1"
                        :disabled="!canSelectNetworks"
                        :readonly="!canSelectNetworks"
                        v-model="user.allChannelNetworks"
                        label="All Channel Networks" />
                    </div>

                    <v-expand-transition>
                      <!--:disabled="user.allChannelNetworks || !canSelectNetworks"-->
                      <!--:readonly="user.allChannelNetworks || !canSelectNetworks"-->
                      <v-autocomplete
                        v-show="!user.allChannelNetworks && canSelectNetworks"
                        v-model="user.networks"
                        label="Channel Networks"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        outlined
                        :placeholder="user.allChannelNetworks ? 'All Channel Networks' : 'Select Networks'"
                        :items="availableNetworks" />
                    </v-expand-transition>
                  </div>

                </v-expand-transition>

              </v-col>

              <v-col cols="1" class="px-4">
                <v-switch
                  label="CashSpot"
                  inset
                  v-model="user.isCashSpotUser" />
              </v-col>

              <v-col cols="12" class="px-4 pl-6">
                <v-expand-transition>

                  <v-autocomplete
                    v-show="user.isCashSpotUser"
                    v-model="user.cashSpotTerminal"
                    label="Terminal"
                    chips
                    clearable
                    deletable-chips
                    outlined
                    placeholder="Select Terminal"
                    :items="availableTerminals" />

                </v-expand-transition>

              </v-col>

            </v-row>

            <v-alert v-if="error" type="error">
              {{ error }}
            </v-alert>

        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

          <v-btn text width="72" @click="dialog = false;">Cancel</v-btn>

          <v-spacer v-if="$vuetify.breakpoint.xsOnly" />

          <v-btn color="primary" type="submit" :disabled="!canSave" :loading="saving">Create</v-btn>
        </v-card-actions>

      </v-card>

    </v-form>

  </v-dialog>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {

  data() {
    return {
      dialog: false,
      isValid: false,
      saving: false,
      error: undefined,

      showPassword: false,

      user: {},
      active: true,

      rules: {
        emailOrEmpty: v => (v || '').trim().length == 0 || /.+@.+\..+/.test(v) || 'E-mail must be valid',
        required: value => !!value || 'Required',
        min(len) { return v => v && v.length >= len || `Minimum ${len} characters` },
        phoneOrEmpty(len) { return v => {
          v = (v?.replace(/\D/g,'') || '').trim()
          return v.length == 0 || (v && v.length >= len) || `Minimum ${len} characters`
        } },
      },
    }
  },

  computed: {
    ...mapGetters('Channel', ['channelById', 'networkById', 'channels', 'networks', 'terminals']),

    canSave() { return this.isDirty() && this.isValid },

    canSelectNetworks() {
      const user = this.user

      if (user.allChannels) {
        return true
      }
      else if ((user.channels || []).length > 0) {
        return true
      }

      return false
    },

    availableTerminals() {
      return this.terminals.map(terminal => {
        const network = this.networkById(terminal.network_id)

        if (!network) console.error('Networks have not been retrieved yet')

        const channel = this.channelById(network.channel_id)
        return { text: `${channel.name} / ${network.name} / ${terminal.name}`, value: terminal.id }
      })
    },

    availableChannels() {
      return this.channels
        .map(c => ({text: c.name, value: c.id}))
        .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
    },

    availableNetworks() {
      const user = this.user
      const userChannelIds = user.channels ?? []

      if (user.allChannels) {
        return this.networks
          .map(n => ({text: `${this.channelById(n.channel_id).name} / ${n.name}`, value: n.id}))
          .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
      }
      else {
        const channelsById = Object.fromEntries(userChannelIds.map(id => [id, true]))
        return this.networks
          .filter(n => channelsById[n.channel_id])
          .map(n => ({text: `${this.channelById(n.channel_id).name} / ${n.name}`, value: n.id}))
          .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
      }
    },
  },

  methods: {
    ...mapActions('User', ['addUser']),

    initData() {
      this.saving = false
      this.error = undefined

      this.user = {}
      this.active = true

      this.$refs.form.resetValidation()

//      {
//        isCardNetUser: false,
//        allChannels: false,
//        allChannelNetworks: false,
//        channels: [],
//        networks: [],
//
//        isCashSpotUser: false,
//        cashSpotTerminal: ''
//      }
    },

    isFieldDirty(name) {
      return (this.user[name]?.trim() ?? '') != ''
    },

    isDirty() {
      let user    = _.pickBy(this.user, _.identity)

      if (!this.active) return true

      if (!user.isCardNetUser) delete user.channels && delete user.networks
      if (!user.channels?.length) delete user.channels
      if (!user.networks?.length) delete user.networks
      if (user.allChannels) delete user.channels
      if (user.allChannelNetworks) delete user.networks

      const isDirty = Object.keys(user).length > 0

      return isDirty
    },

    async save() {

      if (!this.canSave) return

      this.saving = true

      let user = this.user

      // Filter networks if availableNetworks has changed since selection
      // Server does this check, probably not necessary here
      if (user.channels?.length) {
        const availableNetworksById = Object.fromEntries(this.availableNetworks.map(n => [n.id, true]))
        user.networks = user.networks?.filter(n => availableNetworksById[n.id])
      }

      if (user.allChannelNetworks) {
        delete user.networks
      }

      if (user.allChannels) {
        delete user.channels
      }

      user.disabled = !this.active

      this.addUser(user)

      .then(addedUser => {
        this.saving = false
        this.dialog = false
        this.$router.push({name: 'user', params: { account_id: addedUser.account_id }})
      })

      .catch(error => {
        this.error = error?.response?.data ?? error
        this.saving = false
      })
    }
  },

  watch: {

    dialog: {
      handler() { if (this.dialog) this.initData() },
      immediate: true,
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-card__title {
    background-color: var(--v-primary-base);
    color: var(--v-headerText-base);
  }
  .v-input--is-label-active label {
    font-weight: bold !important;
    color: var(--v-primary-base);
  }

  .v-card__title.headline {
    .v-input--switch label {
      color: white;
    }
  }

  /*
    Fix for autocomplete placeholder only showing on focus
    https://github.com/vuetifyjs/vuetify/issues/11553
  */

  .v-autocomplete:not(.v-input--is-focused).v-select--chips input { max-height: 25px !important; }
  /*
  .v-input--is-label-active label {
    color: white !important;
    font-weight: bold;
  }
  */
}
</style>

