<template>

  <v-container
    fluid
    class="ma-0 pa-0"
    >

    <div
      class="ml-sm-4"
      v-if="this.$vuetify.breakpoint.mdAndUp || this.$route.name == 'users'"
      :style="$vuetify.breakpoint.mdAndUp
        ? 'display: grid; grid-template-columns: 300px auto;'
        : 'position: absolute; left: 0; bottom: 0; top: 0; right: 0; display: grid; grid-template-columns: auto;'"
      >

      <div style="grid-column: 1;">

        <div
          :style="$vuetify.breakpoint.mdAndUp
            ? 'width: 300px; position: sticky; overflow-y: scroll;'
            : ''
            ">

          <v-list
            v-model="userSelectedIndex">

            <v-list-item class="pt-0 px-2">
              <NewUserDialog />

              <v-spacer />

            </v-list-item>

            <v-list-item class="pa-0">
              <v-text-field
                dense
                hide-details
                outlined
                clearable
                label="Name, Username"
                v-model="userFilter"
                @click:clear="clearUserFilter"
                class="mt-2" />
            </v-list-item>

            <v-list-item
              v-for="item in filteredUserList"
              :key="item.id"
              link
              :to="{name: 'user', params: { account_id: item.account_id }}">

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.account_name }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ identitiesByAccountId(item.account_id).map(i => i.username).join(',') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{
                    compact([
                      isPortalUser(item.account_id) ? 'CardNet' : undefined,
                      ,
                      isClerkUser(item.account_id) ? 'CashSpot' : undefined,
                    ]).join(', ')
                  }}
                </v-list-item-subtitle>

              </v-list-item-content>

              <v-list-item-icon class="pr-2">
                <v-icon :style="{visibility: isUserActive(item.account_id) ? 'hidden' : 'visible'}" color="red" >mdi-minus-circle-outline</v-icon>

                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

        </div>
      </div>

      <div
        class="mt-sm-1 ml-sm-1"
        v-if="$vuetify.breakpoint.mdAndUp"
        style="grid-column: 2; overflow: scroll;"
        >
        <router-view />
      </div>
    </div>

    <router-view v-else />

  </v-container>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import PhoneNumber from 'awesome-phonenumber'
import NewUserDialog from '@/components/NewUserDialog.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      userFilter: '',
      userSelectedIndex: undefined,
      userSelected: 0,
      filteredUserList: [],
      accountId: undefined,

      newUserDialog: false,
    }
  },

  metaInfo() {
    return {
      title: 'Users',
    }
  },

  computed: {
    ...mapGetters('User', ['users', 'identitiesByAccountId', 'isUserActive', 'isPortalUser', 'isClerkUser']),
  },

  components: {
    NewUserDialog,
  },

  methods: {
    ...mapActions('User', [ 'getUsers' ]),

    compact: (array) => _.compact(array),

    formatPhone: (phone) => phone ? PhoneNumber(phone.replace(/\D/gi, ''), 'US').getNumber('national') : undefined,

    clearUserFilter() {
      this.userFilter = ''
    },

    filterUserList() {
      const filter = this.userFilter?.toLowerCase() ?? ''
      const users = this.users.filter(u => !u.roles.some(r => r.name == 'worker'))


      const filteredUserList = filter.length == 0
        ? users
        : users.filter(user =>
            user.account_name?.toLowerCase().includes(filter) ||
            user.account_name?.toLowerCase().includes(filter) ||
            user.identities.map(i => Object.fromEntries(_.chunk(i, 2)).username).join(' ').toLowerCase().includes(filter)
          )

      this.filteredUserList = [...filteredUserList]
    },
  },

  mounted() {
    this.getUsers()
  },

  watch: {

    users: {
      handler() {
        this.filterUserList()
      },
      immediate: true,
    },

    userFilter: {
      handler() { this.filterUserList() },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
/*::v-deep {
  .v-badge--inline .v-badge__badge {
    background-color: inherit !important;
    width: 0.7em;

    &:after {
    border-color: gray;
  }
  }
}
*/
</style>

